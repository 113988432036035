import { SAVE_SNELSTART_ORDERS } from "../constants/Snelstart";

const initState = null;

const snelstart = (state = initState, action) => {
  switch (action.type) {
    case SAVE_SNELSTART_ORDERS:
      const newOrders = action.payload;
      return {
        ...state,
        orders: newOrders,
      };
    default:
      return state;
  }
};

export default snelstart;
