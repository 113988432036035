// Tables
export const FIRESTORE_REGIONS_TABLE = "regions";
export const FIRESTORE_ORDERS_TABLE = "farmorders";
export const FIRESTORE_PRODUCTS_TABLE = "products";
export const FIRESTORE_CUSTOMERS_TABLE = "customers";
export const FIRESTORE_USERS_TABLE = "users";
export const FIRESTORE_INVITED_USERS_TABLE = "invitedUsers";
export const FIRESTORE_NOTIFICATIONS_TABLE = "notifications";
export const FIRESTORE_CUSTOMER_REQUESTS_TABLE = "customer_requests";
export const FIRESTORE_CUSTOMER_GROUPS_TABLE = "customerGroups";
export const FIRESTORE_DISCOUNT_CODES_TABLE = "discountCodes";

// Variables
export const FIRESTORE_PRODUCT_VARIATIONS_PRICE = "price";
export const FIRESTORE_DEFAULT_ORDERS_MAX_DAYS = 30;
