import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/reports`} component={lazy(() => import(`./reports`))} />
        <Route path={`${APP_PREFIX_PATH}/products`} component={lazy(() => import(`./products`))} />
        <Route path={`${APP_PREFIX_PATH}/orders`} component={lazy(() => import(`./orders`))} />
        <Route path={`${APP_PREFIX_PATH}/customers`} component={lazy(() => import(`./customers`))} />
        <Route
          path={`${APP_PREFIX_PATH}/companies`}
          component={lazy(() => import(`views/app-views/customers/companies`))}
        />
        <Route path={`${APP_PREFIX_PATH}/notifications`} component={lazy(() => import(`./notifications`))} />
        <Route path={`${APP_PREFIX_PATH}/groups`} component={lazy(() => import(`./groups`))} />
        <Route path={`${APP_PREFIX_PATH}/discounts`} component={lazy(() => import(`./discounts`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/products`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
